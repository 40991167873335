import React from 'react';
import ReactDOM from 'react-dom/client';
import { Button, Navbar } from '@blueprintjs/core';
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import { PagesTimeline } from 'polotno/pages-timeline';
import { SidePanel, DEFAULT_SECTIONS } from 'polotno/side-panel';
import { Workspace } from 'polotno/canvas/workspace';
import {
  unstable_setAnimationsEnabled,
  unstable_setTextOverflow,
} from 'polotno/config';
import { UploadPanel } from './upload-panel';
import { TemplatesPanel } from './templates-panel';
import { useMobile } from 'polotno/utils/screen';
import { setTranslations } from 'polotno/config';
import { StableDiffusionSection } from './sections/ai-images-section';
import { DownloadVideoButton } from './download-video-button';

import '@blueprintjs/core/lib/css/blueprint.css';

import { createStore } from 'polotno/model/store';

const getParam = (name) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(name);
};

const store = createStore({
  key: getParam('KEY'),
});
const page = store.addPage();

const width = parseFloat(getParam('width'));
const height = parseFloat(getParam('height'));
if (width && height) {
  store.setSize(width, height);
}

const unit = getParam('unit');
if (unit) {
  store.setUnit({ unit });
}

let sections = DEFAULT_SECTIONS;
sections.find((s) => s.name === 'upload').Panel = UploadPanel;
sections.find((s) => s.name === 'templates').Panel = TemplatesPanel;
sections.push(StableDiffusionSection);

const defaultSection = getParam('defaultSection') || 'photos';

if (getParam('sections')) {
  sections = getParam('sections')
    .split(',')
    .map((name) => {
      // special case for 'false' string
      if (name === 'false') {
        return false;
      }
      const section = DEFAULT_SECTIONS.find((s) => s.name === name);
      if (!section) {
        console.error(`Section ${name} not found`);
      }
      return section;
    })
    .filter(Boolean);
}

async function loadJSON(url) {
  const response = await fetch(url);
  const json = await response.json();
  if (getParam('initialTextOverflow')) {
    unstable_setTextOverflow(getParam('initialTextOverflow'));
  }
  store.loadJSON(json);
  if (width && height) {
    store.setSize(width, height, true);
  }
  await store.waitLoading();
  unstable_setTextOverflow('resize');
}

unstable_setAnimationsEnabled(getParam('animationsEnabled') === 'true');

if (getParam('jsonUrl')) {
  loadJSON(getParam('jsonUrl'));
}

const handleChange = () => {
  var myCustomData = {
    type: 'change',
    json: store.toJSON(),
  };
  // Send a message to the parent
  window.parent.postMessage(myCustomData, '*');
};

let timeout = null;
const requestChange = () => {
  if (timeout) {
    return;
  }
  timeout = setTimeout(() => {
    handleChange();
    timeout = null;
  }, 3000);
};

store.on('change', () => {
  requestChange();
});

const handleJSONLoad = async (event) => {
  if (event.data?.type !== 'jsonLoad') {
    return;
  }
  if (event.data?.json) {
    if (getParam('initialTextOverflow')) {
      unstable_setTextOverflow(getParam('initialTextOverflow'));
    }
    store.loadJSON(event.data.json);
    await store.waitLoading();
    unstable_setTextOverflow('resize');
  }
};
window.addEventListener('message', handleJSONLoad, false);

const handleSetTranslations = (event) => {
  if (event.data?.type !== 'setTranslations') {
    return;
  }
  if (event.data?.translations) {
    setTranslations(event.data.translations);
  }
};
window.addEventListener('message', handleSetTranslations, false);

// it is important to define component onside of `MyToolbar` render function
const ActionControls = ({ store }) => {
  if (getParam('allowDownload') && getParam('animationsEnabled') === 'true') {
    return <DownloadVideoButton store={store} />;
  }
  return (
    <Button
      intent="primary"
      fill
      onClick={async () => {
        // for demo page on polotno.com
        if (getParam('allowDownload')) {
          store.saveAsImage();
          return;
        }
        var myCustomData = {
          dataURL: await store.toDataURL(),
          type: 'publish',
          json: store.toJSON(),
        };
        // Send a message to the parent
        window.parent.postMessage(myCustomData, '*');
      }}
    >
      {getParam('publishLabel') || 'Publish'}
    </Button>
  );
};

export const App = ({ store }) => {
  const isMobile = useMobile();

  const hasSections = sections.length > 0;

  return (
    <PolotnoContainer style={{ width: '100vw', height: '100vh' }}>
      {/* on mobile let's render publish button into another place, because it is not visible in the toolbar */}
      {isMobile && (
        <Navbar style={{ textAlign: 'center', height: '40px' }}>
          <Navbar.Group
            style={{
              textAlign: 'center',
              height: '40px',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <ActionControls store={store} />
          </Navbar.Group>
        </Navbar>
      )}
      {hasSections && (
        <SidePanelWrap>
          <SidePanel
            store={store}
            sections={sections}
            defaultSection={defaultSection}
          />
        </SidePanelWrap>
      )}
      <WorkspaceWrap>
        <Toolbar
          store={store}
          components={{ ActionControls: isMobile ? undefined : ActionControls }}
        />
        <Workspace store={store} />
        <ZoomButtons store={store} />
        <PagesTimeline store={store} />
      </WorkspaceWrap>
    </PolotnoContainer>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App store={store} />);
